import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

type Card = {
  id: number;
  title: string;
  description: string;
  text: string;
  image: {
    path: string;
    alt: string;
  };
};

type CardDesc = {
  id: number;
  text: string;
  image: {
    path: string;
    alt: string;
  };
};

const About = () => {
  const { t } = useTranslation("", { keyPrefix: "About" });
  const card1: Card = t("cards.0", { returnObjects: true });
  const card2: Card = t("cards.1", { returnObjects: true });
  const card3: CardDesc = t("cards.2", { returnObjects: true });
  const card4: CardDesc = t("cards.3", { returnObjects: true });

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      mt={10}
      id="about-us"
      sx={{
        overflow: "hidden",
        ".MuiGrid-root img": { width: "auto", maxWidth: "100%" },
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        className="left-card"
        sx={{
          position: "relative",
          textAlign: { xs: "center", md: "unset" },
          "&:hover": { ".desc-card": { right: "-100%" } },
        }}
      >
        <Grid
          container
          item
          bgcolor="primary.light1"
          justifyContent="center"
          sx={{
            p: 10,
            px: { xs: 2, md: 10 },
            py: { xs: 5, md: 10 },
            ".add-line-right::after": { bgcolor: "primary.main" },
          }}
        >
          <Grid
            container
            item
            xs={12}
            md={8}
            sx={{ justifyContent: { xs: "center", md: "unset" } }}
          >
            <Grid item>
              <Typography
                variant="subtitle2"
                letterSpacing={3.5}
                color="primary.main"
                fontWeight={700}
                data-aos="fade-right"
                sx={isMatch ? { fontSize: "1rem" } : {}}
              >
                <span className={isMatch ? "" : "add-line-right"}>
                  {card1.title}
                </span>
              </Typography>
              <Typography
                variant="h3"
                fontWeight={700}
                component="h2"
                mt={1}
                data-aos="fade-right"
                data-aos-delay="200"
                sx={isMatch ? { fontSize: "2.5rem" } : {}}
              >
                {card1.description}
              </Typography>
              <Typography mt={5} data-aos="fade-right" data-aos-delay="400">
                {card1.text}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={{ justifyContent: { xs: "center", md: "unset" } }}
          >
            <Grid item xs={12} md={8}></Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mt: { xs: 2, md: 0 },
                justifyContent: { xs: "center", md: "unset" },
              }}
              data-aos="fade-left"
              data-aos-delay="600"
            >
              <img
                src={card1.image.path}
                alt={card1.image.alt}
                style={isMatch ? { height: "50%" } : {}}
              />
              {isMatch && (
                <img
                  style={{ width: "50%", maxHeight: "100%" }}
                  src={card3.image.path}
                  alt={card3.image.alt}
                />
              )}
            </Grid>
          </Grid>
          {isMatch && (
            <Grid container item xs={12}>
              <Typography mt={5} data-aos="fade-right" data-aos-delay="600">
                {card3.text}
              </Typography>
            </Grid>
          )}
        </Grid>

        {!isMatch && (
          <Grid
            container
            item
            justifyContent="space-between"
            className="desc-card"
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "primary.light1",
              p: 5,
              pb: 10,
              right: "-200%",
              zIndex: "1",
              transition: "all 0.6s cubic-bezier(0.75, 0, 0.175, 1)",
              "&:hover": { right: "-200% !important" },
            }}
          >
            <Grid
              item
              xs={5}
              sx={{ display: "grid", alignContent: "flex-end" }}
              data-aos="fade-left"
            >
              <img src={card3.image.path} alt={card3.image.alt} />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ alignSelf: "center" }}
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <Typography>{card3.text}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        className="right-card"
        sx={{
          position: "relative",
          "&:hover": { ".desc-card": { left: "-100%" } },
        }}
      >
        <Grid
          container
          item
          justifyContent="center"
          sx={{
            bgcolor: "tertiary.light",
            p: 10,
            px: { xs: 2, md: 10 },
            py: { xs: 5, md: 10 },
            textAlign: { xs: "center", md: "right" },
            ".add-line-left::before": { bgcolor: "secondary.dark" },
          }}
        >
          <Grid container item xs={12} md={8}>
            <Grid item>
              <Typography
                variant="subtitle2"
                letterSpacing={3.5}
                color="secondary.dark"
                fontWeight={700}
                data-aos="fade-left"
                sx={isMatch ? { fontSize: "1rem" } : {}}
              >
                <span className={isMatch ? "" : "add-line-left"}>
                  {card2.title}
                </span>
              </Typography>
              <Typography
                variant="h3"
                fontWeight={700}
                component="h2"
                mt={1}
                data-aos="fade-left"
                data-aos-delay="200"
                sx={isMatch ? { fontSize: "2.5rem" } : {}}
              >
                {card2.description}
              </Typography>
              <Typography mt={5} data-aos="fade-left" data-aos-delay="400">
                {card2.text}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mt: { xs: 2, md: 0 },
                justifyContent: { xs: "center", md: "unset" },
              }}
              data-aos="fade-right"
              data-aos-delay="600"
            >
              {isMatch && (
                <img
                  src={card4.image.path}
                  alt={card4.image.alt}
                  style={{ width: "60%", maxHeight: "100%" }}
                />
              )}
              <img
                src={card2.image.path}
                alt={card2.image.alt}
                style={isMatch ? { height: "60%" } : {}}
              />
            </Grid>
          </Grid>
          {isMatch && (
            <Grid container item xs={12}>
              <Typography mt={5} data-aos="fade-left" data-aos-delay="600">
                {card4.text}
              </Typography>
            </Grid>
          )}
        </Grid>

        {!isMatch && (
          <Grid
            container
            item
            justifyContent="space-between"
            className="desc-card"
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              bgcolor: "tertiary.light",
              p: 5,
              pb: 10,
              left: "-200%",
              zIndex: "1",
              transition: "all 0.6s cubic-bezier(0.75, 0, 0.175, 1)",
              "&:hover": { left: "-200% !important" },
            }}
          >
            <Grid item xs={6} sx={{ alignSelf: "center" }}>
              <Typography>{card4.text}</Typography>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{ display: "grid", alignContent: "flex-end" }}
            >
              <img src={card4.image.path} alt={card4.image.alt} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default About;
