import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Cards = {
  id: number;
  avtarPath: string;
  name: string;
  designation: string;
  review: string;
}[];

const Testimonials = () => {
  const { t } = useTranslation("", { keyPrefix: "Testimonials" });
  const cards: Cards = t("cards", { returnObjects: true });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  function stringAvatar(name: string) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <Grid container id="testimonials">
      <Container sx={{ py: 10, pr: { xs: 0 } }}>
        <Grid container item sx={{ pr: { xs: 2, md: "unset" } }}>
          <Grid item sx={{ ".add-line::after": { bgcolor: "primary.main" } }}>
            <Typography
              variant="subtitle2"
              letterSpacing={3.5}
              color="primary.main"
              className="add-line"
              fontWeight={700}
              data-aos="fade-up"
              sx={isMatch ? { fontSize: "1rem" } : {}}
            >
              {t("title")}
            </Typography>
          </Grid>
          <Grid container item justifyContent="space-between" mt={1}>
            <Grid>
              <Typography
                variant="h3"
                fontWeight={700}
                component="h2"
                data-aos="fade-up"
                data-aos-delay="200"
                sx={isMatch ? { fontSize: "2.5rem" } : {}}
              >
                {t("description")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={4}
          mt={5}
          sx={
            isMatch
              ? {
                  overflowX: "scroll",
                  flexWrap: "nowrap",
                  scrollbarWidth: "none",
                  pr: 2,
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }
              : {}
          }
        >
          {cards.map((card, i) => (
            <Grid
              item
              xs={10}
              md={4}
              key={card.id}
              sx={{ display: "flex", flex: "none" }}
            >
              <Card
                sx={{
                  borderRadius: "10px",
                  p: "1rem",
                  textAlign: "center",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                elevation={26}
                data-aos="fade-up"
                data-aos-delay={200 * i}
              >
                <CardMedia sx={{ mt: 2 }}>
                  <Avatar
                    alt={card.name}
                    src={card.avtarPath}
                    {...stringAvatar(card.name)}
                    sx={{ m: "auto", width: 84, height: 84 }}
                  ></Avatar>
                </CardMedia>
                <CardHeader
                  title={card.name}
                  titleTypographyProps={{
                    fontSize: "24px",
                    fontWeight: 600,
                  }}
                  subheader={card.designation}
                  sx={{ px: 0 }}
                ></CardHeader>
                <CardContent sx={{ px: 0 }}>
                  <Typography>{card.review}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Testimonials;
