import { Container, Grid } from "@mui/material";
import Contact from "./Contact";
import Pages from "./Pages";
import Copyrights from "./Copyrights";

const Footer = () => {
  return (
    <Grid
      container
      bgcolor="grey.800"
      component="footer"
      color="grey.50"
      sx={{
        ".MuiLink-root": {
          color: "grey.200",
          "&:hover": { color: "tertiary.main" },
        },
        ".MuiListItemIcon-root": { color: "grey.200" },
        ".MuiListItemButton-root": {
          borderColor: "grey.700",
          "&:hover": { color: "tertiary.main" },
        },
        ".active": { color: "tertiary.main" },
      }}
    >
      <Container>
        <Grid container py={8} spacing={8}>
          <Grid container item xs={12} md={6}>
            <Pages />
          </Grid>
          <Grid container item xs={12} md={6}>
            <Contact />
          </Grid>
        </Grid>
      </Container>
      <Grid container bgcolor="grey.900" py={4}>
        <Container>
          <Copyrights />
        </Container>
      </Grid>
    </Grid>
  );
};

export default Footer;
