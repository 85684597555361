import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomIIotSolutions = () => {
  const { t } = useTranslation("", { keyPrefix: "CustomSolution" });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid
        container
        sx={{ borderRadius: 4, bgcolor: { xs: "#DDF5F4" } }}
        overflow={"hidden"}
        m={0.5}
        mt={3}
        spacing={3}
        xs={12}
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <CardContent>
              <Typography
                variant="h6"
                color="textPrimary"
                fontWeight={700}
                component="h2"
                style={isMatch ? { fontSize: "1rem" } : {}}
              >
                {t("customIndustry.title")}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginTop: "8px" }}
              >
                {t("customIndustry.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <CardContent>
              <Typography
                variant="h6"
                color="textPrimary"
                fontWeight={700}
                component="h2"
                style={isMatch ? { fontSize: "1rem" } : {}}
              >
                {t("costEffective.title")}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginTop: "8px" }}
              >
                {t("costEffective.description")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9} mt={5}>
        <hr
          style={{
            backgroundColor: "#62DAD8",
            border: "none",
            height: "1px",
          }}
        />
      </Grid>
    </>
  );
};

export default CustomIIotSolutions;
