import { Button, Grid, Typography } from "@mui/material";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { HashNavLink } from "./custom/HashNav";

const CustomIIotIndroduction = () => {
  const { t } = useTranslation("", { keyPrefix: "CustomIIot" });

  const clickHandler = () => {
    ReactGA.event({
      category: "Button",
      action: "Get to know us",
      label: "Bookmark",
    });
  };

  return (
    <Grid
      container
      sx={{ textAlign: { xs: "center", md: "unset" }, mt: { xs: 0, md: 10 } }}
    >
      <Grid container item xs={12} md={5}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: "2.5rem", md: "3rem" },
              pt: { xs: 4, md: 0 },
              span: {
                color: "primary.main",
              },
            }}
            data-aos="fade-up"
          >
            {t("title")}
          </Typography>
        </Grid>
        <Grid item xs={12} pt={6} sx={{ display: { md: "none" } }}>
          <img src={t("image.path")} alt={t("image.alt")} />
        </Grid>
        <Grid item xs={12} mt={6}>
          <Typography variant="body1" data-aos="fade-up" data-aos-delay="200">
            {t("description")}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={6}>
          <Button
            onClick={() => clickHandler()}
            component={HashNavLink}
            to={t("links.0.url")}
            variant="contained"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="400"
            style={{ backgroundColor: "#57CFCD", marginBottom: "60px" }}
          >
            {t("links.0.title")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ display: { xs: "none", md: "unset" } }}
        item
        xs={12}
        md={7}
      >
        <img
          src={t("image.path")}
          alt={t("image.alt")}
          style={{ width: "552px", height: "257.96px", marginLeft: "120px" }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomIIotIndroduction;
