import {
  Grid,
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const OurApproach = () => {
  const { t } = useTranslation("", { keyPrefix: "OurApproch" });
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  type Steps = {
    id: number;
    title: string;
    description: string;
    iconPath: string;
    lists: { id: number; title: string }[];
  }[];

  const steps: Steps = t("steps", { returnObjects: true });

  return (
    <Container>
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontWeight: 700, paddingTop: { xs: 4, md: 10 } }}
      >
        {t("title")}
      </Typography>
      <Grid
        justifyContent="center"
        justifyItems="center"
        alignContent="center"
        spacing={4}
        sx={{ display: { md: "flex" }, paddingLeft: { md: 6 }, paddingTop: 4 }}
      >
        {steps.map((step, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            key={index}
            sx={{
              width: { md: 400 },
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(87, 207, 205, 0.1)",
                  borderRadius: "50%",
                  width: 56,
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  color: "#57CFCD",
                  marginBottom: 2,
                }}
              >
                {step.id}
              </Box>
              {index < steps.length - 1 && isMdUp && (
                <Box sx={{ marginLeft: 1, width: 230 }}>
                  <img src={step.iconPath} alt="arrow" />
                </Box>
              )}
            </Box>
            <Typography
              variant="h6"
              fontSize={16}
              sx={{ fontWeight: 600, marginBottom: { xs: 0, md: 1 } }}
            >
              {step.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                paddingRight: { xs: 8, md: 4 },
                paddingLeft: { xs: 8, md: 0 },
                paddingBottom: { xs: 3 },
              }}
            >
              {step.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default OurApproach;
