import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const OurWorkshop = () => {
  const { t } = useTranslation("", { keyPrefix: "OurWorkshop" });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();

  const description = (
    <Trans t={t} components={{ span: <span /> }}>
      {t("description")}
    </Trans>
  );

  type Cards = {
    id: number;
    title: string;
    description: string;
    price: string;
  }[];

  const cards: Cards = t("cards", { returnObjects: true });

  const handleBookNow = () => {
    history.push("/contact-form?topic=Book%20Workshop");
  };

  return (
    <Container sx={{ py: { xs: 1, md: 10 } }}>
      <Grid
        id="our-workshop"
        container
        mt={10}
        sx={{
          ".MuiTypography-root": { textAlign: "center" },
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="primary.main"
            fontWeight={700}
            letterSpacing={3.5}
            data-aos="fade-up"
            paddingBottom={1}
            sx={isMatch ? { fontSize: "1rem" } : {}}
          >
            {t("title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            fontWeight={700}
            component="h2"
            data-aos="fade-up"
            sx={isMatch ? { fontSize: "2.5rem" } : {}}
          >
            {t("subtitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2} width={471}>
          <Typography
            textAlign="center"
            sx={{
              span: {
                fontWeight: 700,
              },
            }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ ".MuiCard-root": { borderRadius: "10px 10px 8px 8px" } }}
          spacing={5}
          mt={3}
        >
          {cards.map((card, i) => (
            <Grid item xs={12} md={4} key={card.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "relative",
                  pt: 2,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 4,
                    left: 0,
                    right: 0,
                    height: 14,
                    backgroundColor: "#AF5CD6",
                    borderRadius: "8px 8px 8px 8px",
                  }}
                />
                <CardContent sx={{ p: 3.5, minHeight: 250 }}>
                  <Typography variant="h6" fontWeight={700}>
                    {card.title}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="start"
                    mb={1}
                    pt={3}
                    sx={{
                      ".MuiTypography-root": { textAlign: "left" },
                    }}
                  >
                    <CheckCircleIcon sx={{ color: "#AF5CD6", mr: 1 }} />
                    <Typography
                      variant="body1"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </CardContent>
                <Grid>
                  <hr
                    style={{
                      width: "318px",
                      backgroundColor: "#C5C5C5",
                      margin: "10px",
                      border: "none",
                      height: "1px",
                    }}
                  />
                </Grid>
                <CardActions
                  sx={{ justifyContent: "space-between", px: 2, pb: 2 }}
                >
                  <Typography variant="h6" fontWeight={700}>
                    {card.price}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#AF5CD6", color: "#fff" }}
                    onClick={handleBookNow}
                  >
                    {t("button")}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurWorkshop;
