import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import pieImg from "../static/images/work/pie.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AgileIcon from "../static/icons/AgileIcon";
import CostIcon from "../static/icons/CostIcon";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import TransparentIcon from "../static/icons/TransparentIcon";
import { useTranslation } from "react-i18next";

const Work = () => {
  const { t } = useTranslation("", { keyPrefix: "Work" });

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      mt={10}
      sx={{
        mt: { xs: 5, md: 10 },
        ".MuiTypography-root": { textAlign: "center" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="primary.main"
          fontWeight={700}
          letterSpacing={3.5}
          data-aos="fade-up"
          sx={isMatch ? { fontSize: "1rem" } : {}}
        >
          {t("title")}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography
          variant="h3"
          fontWeight={700}
          component="h2"
          data-aos="fade-up"
          data-aos-delay="200"
          sx={isMatch ? { fontSize: "2.5rem" } : {}}
        >
          {t("description")}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        mt={10}
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <Grid
          container
          direction="column"
          xs={12}
          md={2.8}
          item
          justifyContent="space-evenly"
          alignItems="flex-end"
          spacing={isMatch ? 6 : undefined}
          style={isMatch ? { alignItems: "center" } : {}}
        >
          <Grid item>
            <Card
              sx={{
                borderRadius: 3.5,
                position: "relative",
                overflow: "visible",
              }}
              elevation={27}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "primary.dark",
                  backgroundColor: "primary.light",
                  p: "0.5rem",
                  borderRadius: "50%",
                  lineHeight: 0,
                }}
              >
                <AccessTimeIcon />
              </Box>
              <CardContent sx={{ pt: 3 }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  fontWeight={700}
                  color="primary.dark"
                >
                  {t("left-cards.0.title")}
                </Typography>
                <Typography>{t("left-cards.0.description")}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                borderRadius: 3.5,
                position: "relative",
                overflow: "visible",
              }}
              elevation={27}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "secondary.main",
                  backgroundColor: "secondary.light",
                  p: "0.5rem",
                  borderRadius: "50%",
                  lineHeight: 0,
                }}
              >
                <AgileIcon viewBox="0 0 25 24" />
              </Box>
              <CardContent sx={{ pt: 3 }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  fontWeight={700}
                  color="secondary.main"
                >
                  {t("left-cards.1.title")}
                </Typography>
                <Typography>{t("left-cards.1.description")}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                borderRadius: 3.5,
                position: "relative",
                overflow: "visible",
              }}
              elevation={27}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "tertiary.main",
                  backgroundColor: "tertiary.light1",
                  p: "0.5rem",
                  borderRadius: "50%",
                  lineHeight: 0,
                }}
              >
                <CostIcon />
              </Box>
              <CardContent sx={{ pt: 3 }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  fontWeight={700}
                  color="tertiary.main"
                >
                  {t("left-cards.2.title")}
                </Typography>
                <Typography>{t("left-cards.2.description")}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {!isMatch && (
          <Grid
            container
            item
            xs={6.4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img src={pieImg} alt="" />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          xs={12}
          md={2.8}
          item
          justifyContent="space-evenly"
          spacing={6}
          sx={{ mt: { xs: 2, md: "auto" } }}
        >
          <Grid item>
            <Card
              sx={{
                borderRadius: 3.5,
                position: "relative",
                overflow: "visible",
              }}
              elevation={27}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "primary.main",
                  backgroundColor: "primary.light2",
                  p: "0.5rem",
                  borderRadius: "50%",
                  lineHeight: 0,
                }}
              >
                <PersonOutlineIcon />
              </Box>
              <CardContent sx={{ pt: 3 }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  fontWeight={700}
                  color="primary.main"
                >
                  {t("right-cards.0.title")}
                </Typography>
                <Typography>{t("right-cards.0.description")}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                borderRadius: 3.5,
                position: "relative",
                overflow: "visible",
              }}
              elevation={27}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "primary.dark",
                  backgroundColor: "primary.light",
                  p: "0.5rem",
                  borderRadius: "50%",
                  lineHeight: 0,
                }}
              >
                <ForumOutlinedIcon />
              </Box>
              <CardContent sx={{ pt: 3 }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  fontWeight={700}
                  color="primary.dark"
                >
                  {t("right-cards.1.title")}
                </Typography>
                <Typography>{t("right-cards.1.description")}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card
              sx={{
                borderRadius: 3.5,
                position: "relative",
                overflow: "visible",
              }}
              elevation={27}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "secondary.main",
                  backgroundColor: "secondary.light",
                  p: "0.5rem",
                  borderRadius: "50%",
                  lineHeight: 0,
                }}
              >
                <TransparentIcon />
              </Box>
              <CardContent sx={{ pt: 3 }}>
                <Typography
                  variant="h6"
                  fontSize="1rem"
                  fontWeight={700}
                  color="secondary.main"
                >
                  {t("right-cards.2.title")}
                </Typography>
                <Typography>{t("right-cards.2.description")}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Work;
