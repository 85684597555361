import {
  Avatar,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const OurPromise = () => {
  const { t } = useTranslation("", { keyPrefix: "OurPromise" });
  const cards: {
    image: {
      path: string;
      alt: string;
    };
    name: string;
    designation: string;
  }[] = t("cards", { returnObjects: true });

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        my: { xs: 5, md: 10 },
        ".MuiTypography-root": { textAlign: "center" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="primary.main"
          fontWeight={700}
          letterSpacing={3.5}
          data-aos="fade-up"
          sx={isMatch ? { fontSize: "1rem" } : {}}
        >
          {t("title")}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography
          variant="h3"
          fontWeight={700}
          component="h2"
          data-aos="fade-up"
          data-aos-delay="200"
          sx={isMatch ? { fontSize: "2.5rem" } : {}}
        >
          {t("description")}
        </Typography>
      </Grid>
      <Grid container item mt={7} xs={12}>
        {cards.map((card, i) => (
          <Grid xs={4}>
            <Card
              sx={{
                textAlign: "center",
              }}
              elevation={0}
              data-aos="fade-up"
              data-aos-delay={200 * i}
            >
              <CardMedia sx={{ mt: 2 }}>
                <Avatar
                  alt={card.name}
                  src={card.image.path}
                  sx={{
                    m: "auto",
                    width: "40%",
                    height: "auto",
                    aspectRatio: "1/1",
                  }}
                ></Avatar>
              </CardMedia>
              <CardHeader
                title={card.name}
                titleTypographyProps={{
                  fontSize: "24px",
                  fontWeight: 600,
                }}
                subheader={card.designation}
                sx={{ px: 0 }}
              ></CardHeader>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} md={9} mt={5}>
        <hr
          style={{
            width: "10rem",
            backgroundColor: "#4F52FF",
            border: "none",
            height: "1px",
          }}
        />
        <Typography
          variant="body1"
          data-aos="fade-up"
          data-aos-delay="200"
          width="100%"
          my={4}
        >
          “{t("promise")}”
        </Typography>
        <hr
          style={{
            width: "10rem",
            backgroundColor: "#4F52FF",
            marginBottom: "40px",
            border: "none",
            height: "1px",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OurPromise;
