import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation("", { keyPrefix: "Partners" });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container mt={8} bgcolor="#DDF5F4" justifyContent="space-between">
      <Grid
        item
        xs={12}
        md={5}
        order={{ xs: 2, md: 1 }}
        sx={
          isMatch
            ? {
                ".add-line::after": { bgcolor: "#62DAD8" },
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                mt: 3,
                p: 4,
              }
            : { ".add-line::after": { bgcolor: "#62DAD8" }, pl: 16, pt: 10 }
        }
      >
        <Typography
          variant="subtitle2"
          letterSpacing={3.5}
          color="#62DAD8"
          className="add-line"
          fontWeight={700}
          data-aos="fade-up"
          sx={isMatch ? { fontSize: "1rem" } : {}}
        >
          {t("title")}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={700}
          component="h2"
          mt={1}
          data-aos="fade-up"
          data-aos-delay="200"
          sx={isMatch ? { fontSize: "2.5rem" } : {}}
        >
          {t("description")}
        </Typography>
        <Typography mt={5} data-aos="fade-up" data-aos-delay="400">
          {t("text")}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6} order={{ xs: 1, md: 2 }}>
        <img
          style={{
            objectFit: "cover",
            height: "450px",
            width: "100%",
          }}
          src={t("image.path")}
          alt={t("image.alt")}
        />
      </Grid>
    </Grid>
  );
};

export default Partners;
