import { useState } from "react";
import { Grid, Typography, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { HashNavLink } from "./custom/HashNav";

const TextBox = () => {
  const { t } = useTranslation("", { keyPrefix: "TextBox" });

  const [show, setShow] = useState(true);
  const [shouldRender, setRender] = useState(true);
  const animation = show
    ? {}
    : { animation: "fadeOutUnmount .5s", animationFillMode: "forwards" };

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  if (shouldRender)
    return (
      <Grid
        container
        border={1}
        borderColor="grey.A200"
        p={3}
        pr={6}
        data-aos="fade-down"
        data-aos-duration="500"
        sx={{
          ...animation,
          position: "relative",
          textAlign: { xs: "center", md: "unset" },
        }}
        onAnimationEnd={onAnimationEnd}
      >
        <Typography variant="body1" m="auto">
          {t("text")}
          <Link component={HashNavLink} to={t("link.url")} ml={1}>
            {t("link.title")}
          </Link>
        </Typography>
        <Link
          sx={{ position: "absolute", right: "1.5rem" }}
          onClick={() => {
            setShow(false);
          }}
        >
          <CloseIcon />
        </Link>
      </Grid>
    );

  return null;
};

export default TextBox;
