import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";

type FooterItems = { title: string; url: string }[];

const Pages = () => {
  const { t } = useTranslation("", { keyPrefix: "Footer.Pages" });
  const footerItems: FooterItems = t("items", { returnObjects: true });

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="h3" color="grey.50">
          PAGES
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {footerItems.map(({ title, url }) => (
            <ListItem disablePadding key={title}>
              <ListItemButton href={url} disableGutters disableRipple divider>
                <KeyboardArrowRightIcon />
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </>
  );
};

export default Pages;
