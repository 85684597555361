import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import AOS from "aos";
import App from "./App";
import theme from "./utils/theme";
import "./utils/styles.css";

AOS.init({
  // @ts-ignore
  easing: "custom-easing",
  anchorPlacement: "top-center",
  once: true,
  duration: 600,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
