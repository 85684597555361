import React, { useState, useEffect, FC, ChangeEvent } from "react";
import {
  Alert,
  Button,
  Container,
  Grid,
  Snackbar,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Box,
  Link,
  SelectChangeEvent,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import http from "../services/httpService";

const ContactForm = () => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("", { keyPrefix: "ContactForm" });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    topic: "",
    message: "",
  });
  const [formError, setFormError] = useState({
    name: "",
    email: "",
  });
  const handleChange = (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;

    console.log("Event", event);

    switch (name) {
      case "name": {
        const nameRegex = /^[A-Za-z\s]+$/;
        if (value.match(nameRegex) || value === "") {
          setFormData((prev) => ({
            ...prev,
            [name]: value,
          }));
          setFormError((prev) => ({
            ...prev,
            [name]: "",
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            [name]: t("nameRejex"),
          }));
        }
        break;
      }
      case "email": {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (value.match(emailRegex) || value === "") {
          setFormError((prev) => ({
            ...prev,
            [name]: "",
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            [name]: t("emailRejex"),
          }));
        }
        break;
      }
      default: {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
      }
    }
  };

  useEffect(() => {}, [formError, formData]);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const topicParam = queryParams.get("topic");
    if (topicParam) {
      setFormData((prev) => ({
        ...prev,
        topic: topicParam,
      }));
    }
  }, [location]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (formData) {
        await http.post("/submit", formData);
        setShowSuccessMsg(true);
        setFormData({ name: "", email: "", topic: "", message: "" });
      } else {
        setShowErrorMsg(true);
      }
    } catch (error) {
      setShowErrorMsg(true);
    } finally {
      setIsLoading(false);
    }
  };

  const InfoBox: FC<{ label: string; value: string; link?: string }> = ({
    label,
    value,
    link,
  }) => (
    <Box>
      <Typography sx={{ display: { xs: "inline-flex" } }} variant="subtitle2">
        {label}
      </Typography>
      <Typography
        component={link ? Link : "span"}
        href={link || undefined}
        sx={{
          display: { xs: "inline-flex" },
          pl: 1,
          color: "#AF5CD6",
          textDecoration: "none",
          "&:hover": { textDecoration: "underline" },
        }}
        variant="subtitle2"
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <Container id="contact-form">
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          data-aos="fade-up"
          sx={{
            textAlign: { xs: "center", md: "start" },
            py: { xs: 4, md: 6 },
          }}
        >
          <Typography
            fontWeight="600"
            color="black"
            variant="h4"
            sx={{ pb: { xs: 4, md: 7 } }}
          >
            {t("title")}
          </Typography>
          <img
            src="/static/images/promise/Flo.png"
            alt="Contact person"
            data-aos="fade-up"
            style={{
              width: 200,
              height: 200,
              borderRadius: "50%",
              marginBottom: "1rem",
            }}
          />
          <Typography
            variant="body2"
            sx={{ pb: { xs: 6, md: 10 }, pr: { xs: 0, md: 10 } }}
          >
            <Trans t={t} components={{ br: <br /> }}>
              {t("subtitle")}
            </Trans>
          </Typography>
          <InfoBox
            label="Email:"
            value={t("email")}
            link={`mailto:${t("email")}`}
          />
          <InfoBox
            label="Phone:"
            value={t("phone")}
            link={`tel:${t("phone")}`}
          />
          <Button
            variant="contained"
            color="primary"
            href="https://outlook.office.com/bookwithme/user/745871c4500046d4880ff015d76ee6ac@smartdings.com?anonymous&ep=pcard"
            target="_blank"
            data-aos="fade-up"
            sx={{ marginTop: { xs: "2rem", md: "2.2rem" }, width: 160 }}
          >
            Book a meeting
          </Button>
          <Typography
            variant="body2"
            sx={{
              marginTop: "1rem",
              pr: { xs: 0, md: 10 },
              pt: { xs: 2, md: 8 },
              textAlign: { xs: "center", md: "start" },
              color: "#7A6682",
            }}
          >
            {t("message")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            px: { xs: 2, md: 2 },
            py: { xs: 0, md: 2 },
            pr: { xs: 2, md: 8 },
            mb: { xs: 2, md: 0 },
            bgcolor: "#AF5CD6",
            color: "#fff",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="500"
            sx={{ pl: { xs: 2, md: 2 }, pt: 5, pb: 3 }}
          >
            {t("formtitle")}
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff", mb: 1 }}>
                Name
              </Typography>
              <input
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder={t("inputs.name")}
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  border: "1px solid #AF5CD6",
                  backgroundColor: "#fff",
                  color: "#7A6682",
                  width: "100%",
                }}
              />
              {formError.name !== "" && (
                <Typography
                  variant="subtitle1"
                  sx={{ color: " #f39c12", mt: 1, pl: 1 }}
                >
                  {formError.name || "Error"}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff", mb: 1 }}>
                Email
              </Typography>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email || ""}
                onChange={handleChange}
                required
                placeholder={t("inputs.email")}
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  border: "1px solid #AF5CD6",
                  backgroundColor: "#fff",
                  color: "#7A6682",
                  width: "100%",
                }}
              />
              {formError.email !== "" && (
                <Typography
                  variant="subtitle1"
                  sx={{ color: " #f39c12", mt: 1 }}
                >
                  {formError.email || "Error"}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff" }}>
                Topic
              </Typography>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Select
                  id="topic"
                  name="topic"
                  color="secondary"
                  displayEmpty
                  required
                  value={formData.topic}
                  onChange={handleChange}
                  sx={{
                    bgcolor: "#fff",
                  }}
                  inputProps={{
                    sx: { color: "#7A6682", fontSize: "0.8rem" },
                  }}
                >
                  <MenuItem value="" disabled>
                    {t("inputs.topic")}
                  </MenuItem>
                  <MenuItem value="Book Workshop">
                    {t("inputs.subtopic.bookWorkshop")}
                  </MenuItem>
                  <MenuItem value="Question">
                    {t("inputs.subtopic.question")}
                  </MenuItem>
                  <MenuItem value="Miscellaneous">
                    {t("inputs.subtopic.miscellaneous")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff", mb: 1 }}>
                Message
              </Typography>
              <textarea
                id="message"
                name="message"
                maxLength={250}
                value={formData.message}
                onChange={handleChange}
                required
                placeholder={t("inputs.message")}
                style={{
                  padding: "10px",
                  height: "8rem",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  color: "#7A6682",
                  width: "100%",
                  fontFamily: "Inter",
                  resize: "none",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  isLoading ||
                  formData.name === "" ||
                  formError.name !== "" ||
                  formData.email === "" ||
                  formError.email !== "" ||
                  formData.topic === "" ||
                  formData.message === ""
                }
                sx={{
                  marginTop: "1rem",
                  bgcolor: "#fff",
                  color: "#a64dff",
                  "&: hover": { color: "#fff" },
                }}
              >
                Send message
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={showSuccessMsg}
        autoHideDuration={3000}
        onClose={() => {
          setShowSuccessMsg(false);
        }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {t("success")}{" "}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorMsg}
        autoHideDuration={3000}
        onClose={() => {
          setShowErrorMsg(false);
        }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {t("error")}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactForm;
