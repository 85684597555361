import { Link as RouterLink } from "react-router-dom";
import { Link, Box } from "@mui/material";
import logo from "../../static/images/logo/smartdingsLogoSmall.png";

const Logo = () => {
  return (
    <Box sx={{ flexGrow: { xs: 0, md: 1 }, m: "auto" }}>
      <Link component={RouterLink} to="/">
        <img alt="smartdings" src={logo} width="191" />
      </Link>
    </Box>
  );
};

export default Logo;
