import { Container, Typography, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Cookies } from "react-cookie-consent";

const handleDeclineCookie = () => {
  Cookies.remove("_ga", { domain: '.www.smartdings.com' });
  Cookies.remove("_ga_5N91GFC3B0", { domain: '.www.smartdings.com' });
  Cookies.remove("CookieConsent");
};

const PrivacyPolicy = () => {
  const { t } = useTranslation("", { keyPrefix: "PrivacyPolicy" });

  return (
    <Container sx={{ py: 10, span: { fontSize: "2rem" } }}>
      <Button
        onClick={() => handleDeclineCookie()}
        variant="contained"
      >
        {t("button")}
      </Button>
      <br/><br/>
      <Typography variant="body1">
        <Trans t={t} components={{ span: <span />, br: <br /> }}>
          {t("text")}
        </Trans>
      </Typography>
    </Container>
  );
};
export default PrivacyPolicy;
