import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const OurTeam = () => {
  const { t } = useTranslation("", { keyPrefix: "OurTeam" });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container mt={10} justifyContent="space-between">
      <Grid
        item
        xs={12}
        md={5}
        sx={{ ".add-line::after": { bgcolor: "primary.main" } }}
      >
        <Typography
          variant="subtitle2"
          letterSpacing={3.5}
          color="primary.main"
          className="add-line"
          fontWeight={700}
          data-aos="fade-up"
          sx={isMatch ? { fontSize: "1rem" } : {}}
        >
          {t("title")}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={700}
          component="h2"
          mt={1}
          data-aos="fade-up"
          data-aos-delay="200"
          sx={isMatch ? { fontSize: "2.5rem" } : {}}
        >
          {t("description")}
        </Typography>
        <img
          src={t("image.path")}
          alt={t("image.alt")}
          width={"100%"}
          data-aos="fade-up"
          data-aos-delay="400"
          style={isMatch ? { marginTop: "20px" } : { display: "none" }}
        />
        <Typography mt={5} data-aos="fade-up" data-aos-delay="400">
          {t("text")}
        </Typography>
      </Grid>
      <Grid item xs={6} style={isMatch ? { display: "none" } : {}}>
        <img
          src={t("image.path")}
          alt={t("image.alt")}
          width={"100%"}
          data-aos="fade-up"
          data-aos-delay="600"
        />
      </Grid>
    </Grid>
  );
};

export default OurTeam;
