import { Link, Button } from "@mui/material";
import { NavHashLink } from "react-router-hash-link";

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const HashNavLink = ({ children, to, ...rest }: any) => {
  return (
    <Link
      component={NavHashLink}
      scroll={(el: HTMLElement) => scrollWithOffset(el)}
      to={to}
      {...rest}
    >
      {children}
    </Link>
  );
};

export const HashNavButton = ({ children, to, ...rest }: any) => {
  return (
    <Button
      component={NavHashLink}
      scroll={(el: HTMLElement) => scrollWithOffset(el)}
      to={to}
      {...rest}
    >
      {children}
    </Button>
  );
};
