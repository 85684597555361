import {
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XingIcon from "../../static/icons/XingIcon";

const Copyrights = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={isMatch ? { flexDirection: "column-reverse" } : {}}>
      <Grid item xs={12} md={6}>
        <Typography>© Copyright - {currentYear} | smartdings GmbH </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          alignItems="flex-start"
        >
          <Link component="a" target="_blank" href="mailto:info@smartdings.com">
            <EmailIcon />
          </Link>
          <Link
            component="a"
            target="_blank"
            href="https://de.linkedin.com/company/smartdings"
          >
            <LinkedInIcon />
          </Link>
          <Link
            component="a"
            target="_blank"
            href="https://www.xing.com/pages/smartdings"
          >
            <XingIcon />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Copyrights;
