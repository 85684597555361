import { Container, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const LegalDisclosure = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ py: 10, span: { fontSize: "2rem" } }}>
      <Typography variant="body1">
        <Trans t={t} components={{ span: <span />, br: <br /> }}>
          {t("LegalDisclosure")}
        </Trans>
      </Typography>
    </Container>
  );
};

export default LegalDisclosure;
