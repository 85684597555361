import { createTheme } from "@mui/material/styles";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";

let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#AF5CD6",
      light1: "#FCF7FF",
      light2: "#FAEFFF",
      light: "#F4F0FF",
      dark: "#886DD1",
    },
    secondary: {
      main: "#6BB3DE",
      dark: "#04A1F4",
      light: "#E4F5FF",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: "#62DAD8",
      light: "#E9EFFD",
      light1: "#EBFFFF",
      dark: "#4F52FF",
    },
    text: {
      primary: "#3a3a3a",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
    },
  },
  transitions: {
    easing: {
      easeOut: "cubic-bezier(0.75, 0, 0.175, 1)",
    },
  },
});

theme.shadows.push(
  "0px 4px 15px 0px #0000000D",
  "0px 0px 15px 2px rgba(24, 37, 45, 0.08)",
  "0px 0px 15px 0px #EBEBEB",
  "0px 0px 15px 5px rgba(175, 92, 214, 0.2)",
  "0px 0px 40px 10px rgba(26, 15, 145, 0.1)"
);

theme = createTheme(theme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          padding: "10px 0",
          position: "sticky",
          ".MuiLink-root": {
            color: theme.palette.text.primary,
            "&:hover, &.Mui-focusVisible": {
              color: theme.palette.primary.main,
            },
            "&.active": {
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
  },
});

export default theme;
