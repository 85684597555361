import {
  Container,
  Grid,
  Typography,
  // Button,
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActions,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// import { Link as RouterLink } from "react-router-dom";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useTranslation } from "react-i18next";

type Cards = {
  id: number;
  title: string;
  description: string;
  imgPath: string;
  portfolioId: string;
}[];

const OurWork = () => {
  const { t } = useTranslation("", { keyPrefix: "OurWork" });
  const cards: Cards = t("cards", { returnObjects: true });

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container id="our-work" bgcolor="primary.light1" mt={10}>
      <Container sx={{ py: 10, pr: { xs: 0 } }}>
        <Grid container item sx={{ pr: { xs: 2, md: "unset" } }}>
          <Grid
            item
            xs={12}
            sx={{ ".add-line::after": { bgcolor: "primary.main" } }}
          >
            <Typography
              variant="subtitle2"
              letterSpacing={3.5}
              color="primary.main"
              className="add-line"
              fontWeight={700}
              data-aos="fade-up"
            >
              {t("title")}
            </Typography>
          </Grid>
          <Grid container item justifyContent="space-between" mt={1}>
            <Grid>
              <Typography
                variant="h3"
                fontWeight={700}
                component="h2"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {t("description")}
              </Typography>
            </Grid>
            {/* <Grid sx={{ display: { xs: "none", md: "unset" } }}>
              <Button
                component={RouterLink}
                variant="contained"
                to={t("link.url")}
                data-aos="fade-up"
                data-aos-delay="400"
              >
                {t("link.title")}
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={4}
          mt={2}
          sx={
            isMatch
              ? {
                  overflowX: "scroll",
                  flexWrap: "nowrap",
                  scrollbarWidth: "none",
                  pr: 2,
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }
              : {}
          }
        >
          {cards.map((card, i) => (
            <Grid
              item
              xs={8}
              md={4}
              key={card.id}
              data-aos="fade-up"
              data-aos-delay={200 * i}
              sx={{ display: "flex", flex: "none" }}
            >
              <Card
                sx={{
                  bgcolor: "transparent",
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                }}
                elevation={0}
              >
                <CardMedia
                  component="img"
                  src={card.imgPath}
                  alt={card.title}
                  sx={{
                    borderRadius: "10px",
                    // @ts-ignore
                    boxShadow: (theme) => theme.shadows[28],
                    marginBottom: 1,
                    aspectRatio: "1/1",
                  }}
                />
                <CardHeader
                  title={card.title}
                  titleTypographyProps={{
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "primary.main",
                    textTransform: "uppercase",
                  }}
                  sx={{ px: 0 }}
                ></CardHeader>
                <CardContent sx={{ px: 0 }}>
                  <Typography>{card.description}</Typography>
                </CardContent>
                <CardActions sx={{ px: 0, mt: "auto" }}>
                  <Link
                    href={`/portfolio/${card.portfolioId}`}
                    underline="none"
                  >
                    Learn More{" "}
                    <TrendingFlatIcon sx={{ verticalAlign: "bottom" }} />
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
};

export default OurWork;
