import { Fragment } from "react";
import { Container, Box } from "@mui/material";
import Introduction from "./Introduction";
import About from "./About";
import Work from "./Work";
import Featured from "./Featured";
import Service from "./Service";
import TextBox from "./TextBox";
import OurWork from "./OurWork";
import OurTeam from "./OurTeam";
import Testimonials from "./Testimonials";
import OurPromise from "./OurPromise";
import OurWorkshop from "./OurWorkshop";
// import Contact from "./Contact";

const Home = () => {
  return (
    <Fragment>
      <TextBox />
      <Container>
        <Introduction />
        <Featured />
        <Service />
      </Container>
      <About />
      <Container>
        <Work />
        <OurWorkshop />
      </Container>
      <OurWork />
      <Container>
        <OurTeam />
      </Container>
      <Box bgcolor="tertiary.light" mt={10}>
        <Testimonials />
        {/* <Contact /> */}
      </Box>
      <Container>
        <OurPromise />
      </Container>
    </Fragment>
  );
};

export default Home;
