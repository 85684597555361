import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import "./i18n";
import "aos/dist/aos.css";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import LegalDisclosure from "./components/LegalDisclosure";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookieBanner from "./components/CookieBanner";
import CustomIIot from "./components/CustomIIot";
import ContactForm from "./components/ContactForm";
import ScrollToTop from "./components/ScrollToTop";

const isProduction = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return false;
  } else {
    return true;
  }
};

const App = () => {
  return (
    <>
      <CssBaseline />
      <Router>
      <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/portfolio/:id" component={Portfolio} />
          <Route exact path="/legal-disclosure" component={LegalDisclosure} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/custom-iiot" component={CustomIIot} />
          <Route exact path="/contact-form" component={ContactForm} />
        </Switch>
        {isProduction() ? <CookieBanner /> : null}
        <Footer />
      </Router>
    </>
  );
};

export default App;
