import { useState } from "react";
import { AppBar, Container, Toolbar } from "@mui/material";
import MobileMenu from "./MobileMenu";
import Logo from "./Logo";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../LanguageSwitch";

export type NavItems = {
  id: number;
  title: string;
  url: string;
  btn: boolean;
}[];

const Header = () => {
  const { t } = useTranslation("", { keyPrefix: "Header" });
  const navItems: NavItems = t("items", { returnObjects: true });

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar component="nav" elevation={25}>
      <Container>
        <Toolbar disableGutters>
          <MobileMenu
            navItems={navItems}
            onDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
          
          <Logo />

          <Menu navItems={navItems} />
          <LanguageSwitch
            sx={{ display: { md: "none" } }}
            shortcode={true}
            underline="none"
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
