import { Grid, Typography, Link } from "@mui/material";

const Contact = () => {
  return (
    <>
      <Grid item xs={12} id="contact-info">
        <Typography variant="subtitle1" component="h3" color="grey.50">
          CONTACT INFO
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="grey.400">
          smartdings GmbH
          <br />
          Lotterbergstraße 30
          <br />
          70499 Stuttgart
          <br />
          Germany
          <br />
        </Typography>
        <Typography>
          Email:{" "}
          <Link underline="none" href="mailto:info@smartdings.com">
            info@smartdings.com
          </Link>
        </Typography>
        <Typography>Tel: +49 711 25253539</Typography>
      </Grid>
    </>
  );
};

export default Contact;
