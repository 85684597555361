import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import {
  Paper,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type FeaturedItems = { id: number; title: string; url: string }[];

const Featured = () => {
  const { t } = useTranslation("", { keyPrefix: "Featured" });
  const featuredItems: FeaturedItems = t("items", { returnObjects: true });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      mt={10}
      data-aos="fade-up"
      sx={{ textAlign: { xs: "center", md: "unset" } }}
    >
      <Grid item xs={12} mb={3}>
        <Typography
          variant="h1"
          fontWeight={700}
          sx={{ fontSize: { xs: "2.5rem", md: "1.5rem" } }}
        >
          {t("heading")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        data-aos="fade-up"
        data-aos-delay="200"
        sx={{
          ".swiper-slide": { p: 2 },
          img: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
          },
          ".MuiPaper-root": { p: 2, height: 70 },
        }}
      >
        <Swiper
          modules={[Autoplay]}
          spaceBetween={50}
          slidesPerView={isMatch ? 2 : 5}
          allowTouchMove={true}
          loop={true}
          autoplay={{ delay: 5000 }}
          style={{ opacity: 0.5 }}
          centeredSlides={true}
        >
          {featuredItems.map(({ id, title, url }) => (
            <SwiperSlide key={id}>
              <Paper elevation={26}>
                <img alt={title} src={url} />
              </Paper>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default Featured;
