import { Container } from "@mui/material";
import { Fragment } from "react";
import CustomIIotIndroduction from "./CustomIIotIntroduction";
import CustomIIotSolutions from "./CustomeIIotSolution";
import OurOffering from "./OurOffering";
import WorkTogether from "./WorkTogether";
import Partners from "./Partners";
import Reference from "./Reference";
import OurApproach from "./OurApproch";

export default function CustomIIot() {
  return (
    <Fragment>
      <Container>
        <CustomIIotIndroduction />
        <CustomIIotSolutions />
        <OurApproach />
        <OurOffering />
      </Container>
      <Partners />
      <Reference />
      <Container>
        <WorkTogether />
      </Container>
    </Fragment>
  );
}
