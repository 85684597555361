import {
  CardActions,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const Reference = () => {
  const { t } = useTranslation("", { keyPrefix: "References" });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container mt={8} bgcolor="#DDF5F4" justifyContent="space-between">
      <Grid container item xs={12} md={6}>
        <img
          style={{
            objectFit: "cover",
            height: "450px",
            width: "100%",
          }}
          src={t("image.path")}
          alt={t("image.alt")}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={
          isMatch
            ? {
                ".add-line::after": { bgcolor: "#62DAD8" },
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                p: 4,
              }
            : { ".add-line::after": { bgcolor: "#62DAD8" } }
        }
      >
        <Typography
          variant="subtitle2"
          letterSpacing={3.5}
          color="#62DAD8"
          className="add-line"
          fontWeight={700}
          pt={10}
          data-aos="fade-up"
          sx={isMatch ? { fontSize: "1rem" } : {}}
        >
          {t("title")}
        </Typography>
        <Typography
          variant="h3"
          fontWeight={700}
          component="h2"
          mt={1}
          data-aos="fade-up"
          data-aos-delay="200"
          sx={isMatch ? { fontSize: "2.5rem" } : {}}
        >
          {t("description")}
        </Typography>
        <Typography mt={5} data-aos="fade-up" data-aos-delay="400">
          {t("text")}
        </Typography>
        <CardActions sx={{ pt: 5, mt: "auto" }}>
          <Link
            href={`/portfolio/${t("portfolioId")}`}
            underline="none"
            sx={{ color: "#62DAD8" }}
          >
            Learn More{" "}
            <TrendingFlatIcon
              sx={{ verticalAlign: "bottom", color: "#62DAD8" }}
            />
          </Link>
        </CardActions>
      </Grid>
    </Grid>
  );
};

export default Reference;
