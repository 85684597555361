import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

const OurOffering = () => {
  const { t } = useTranslation("", { keyPrefix: "OurOffering" });
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  type Cards = {
    id: number;
    title: string;
    iconPath: string;
    lists: { id: number; title: string }[];
  }[];

  const cards: Cards = t("cards", { returnObjects: true });

  return (
    <Grid
      id="service"
      container
      mt={10}
      sx={{
        ".MuiTypography-root": { textAlign: "center" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h3"
          fontWeight={700}
          component="h2"
          data-aos="fade-up"
          sx={isMatch ? { fontSize: "2.5rem" } : {}}
        >
          {t("title")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{ ".MuiCard-root": { borderRadius: 4 } }}
        spacing={5}
        mt={4}
      >
        {cards.map((card, i) => (
          <Grid item xs={12} md={4} key={card.id}>
            <Card
              data-aos="fade-up"
              data-aos-delay={i * 200}
              elevation={0}
              sx={{
                bgcolor: { xs: "#DDF5F4" },
                minHeight: 410,
              }}
            >
              <CardContent sx={{ p: 3.5 }}>
                <CardMedia
                  component="img"
                  image={card.iconPath}
                  height="62"
                  sx={{
                    objectFit: "contain",
                  }}
                ></CardMedia>
                <CardHeader
                  title={card.title}
                  titleTypographyProps={{ fontWeight: 700 }}
                />
                <List
                  sx={{
                    ".MuiTypography-root": { textAlign: "left" },
                    ".MuiListItemIcon-root": {
                      minWidth: 18,
                      padding: 1,
                      color: "#18252D",
                    },
                  }}
                >
                  {card.lists.map((list) => (
                    <ListItem key={list.id}>
                      <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8 }} />
                      </ListItemIcon>
                      <ListItemText primary={list.title} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OurOffering;
